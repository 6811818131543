import 'bootstrap/dist/css/bootstrap.css';
import React, { Suspense } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import 'react-dropzone-uploader/dist/styles.css';
import { Trans, withTranslation } from 'react-i18next';
import { Link, Outlet } from "react-router-dom";
import './App.css';
import logo from './assets/top-logo-MR-nftplazas.png';
import { IAdvertisingState } from './blockchain/types';
import WalletConnect from './component/wallet';

export const PaymentWallet: string = "0x479F3B1a9f9FAc4a97792cE7C92715D190564e3f";
export const LargeScreenDimension = "512x512"
export const SmallScreenDimension = "512x340"

const INITIAL_STATE: IAdvertisingState = {
  metaverse: "",
  address: "",
  network: 0,
  connected: false,
  emailAdddress: "",
  objectId: "",
  dimension: "",
  desiredViews: 0,
  web3: null,
  txHash: "",
  runningTransaction: false,
  base64Img: "",
  contentTypeImg: "",
  errorMsg: "",
  ethUsdcExchangeRate: 1500
};


class App extends React.Component<any, any> {

  public state: IAdvertisingState;

  constructor(props: any) {
    super(props);
    this.onAddressCallback = this.onAddressCallback.bind(this);
    this.onNetworkUpdateCallback = this.onNetworkUpdateCallback.bind(this);
    this.onDisconnectCallback = this.onDisconnectCallback.bind(this);
    this.onEmailCallback = this.onEmailCallback.bind(this);
    this.onDimensionCallback = this.onDimensionCallback.bind(this);
    this.onUploadCallback = this.onUploadCallback.bind(this);
    this.onViewsCallback = this.onViewsCallback.bind(this);
    this.onTxHashCallback = this.onTxHashCallback.bind(this);
    this.onAddressUpdateCallback = this.onAddressUpdateCallback.bind(this);
    this.onExtensionCallback = this.onExtensionCallback.bind(this);
    this.onErrorMsgCallback = this.onErrorMsgCallback.bind(this);

    this.state = {
      ...INITIAL_STATE
    };


    fetch('https://api.coingecko.com/api/v3/simple/price?ids=usd-coin&vs_currencies=eth')
      .then(response => {
        if (!response.ok) {
          return response.json().then(e => new Error(e["message"]))
        }
        return response.json();
      })
      .then(data => {
        if (data instanceof Error) {
          throw data;
        }
        return this.setState({
          ethUsdcExchangeRate: Number(data["usd-coin"]["eth"])
        })
      });
  }

  public async onTxHashCallback(txHash: string) {
    await this.setState({
      txHash: txHash,
      runningTransaction: true
    });
  }

  public async onViewsCallback(views: number) {
    await this.setState({
      desiredViews: views
    });
  }

  public async onUploadCallback(objectId: string) {
    await this.setState({
      objectId: objectId
    });
  }

  public async onEmailCallback(emailAddress: string) {
    await this.setState({
      emailAdddress: emailAddress
    });
  }

  public async onDimensionCallback(dimension: string) {
    await this.setState({
      dimension: dimension,
    });
  }

  public async onAddressCallback(address: string, network: number, web3: any) {
    await this.setState({
      connected: true,
      address,
      network,
      web3,
    });
  }

  public async onNetworkUpdateCallback(network: number) {
    await this.setState({
      network,
    });
  }

  public async onAddressUpdateCallback(address: string) {
    await this.setState({
      address,
    });
  }

  public async onDisconnectCallback(address: string) {
    await this.setState({
      connected: false,
      address: "",
    });
  }

  public async onExtensionCallback(purchaseData: any, base64Img: string, contentTypeImg: string) {
    await this.setState({
      emailAdddress: purchaseData.email,
      objectId: purchaseData.fileName,
      dimension: purchaseData.dimension,
      desiredViews: purchaseData.purchasedViews,
      base64Img: base64Img,
      contentTypeImg: contentTypeImg
    });
  }

  public async onErrorMsgCallback(msg: string) {
    await this.setState({
      errorMsg: msg
    });
  }

  public render = () => {
    return (
      <Suspense fallback="loading" >
        <div className="App">
          <Container className="p-3">
            <Row className='nav-row'>
              <Col className='logo'>
                <Link to='/'>
                  <img src={logo} alt="logo" />
                </Link>
              </Col>
              <WalletConnect
                address={this.state.address}
                onAddressCallback={this.onAddressCallback}
                connected={this.state.connected}
                onDisconnectCallback={this.onDisconnectCallback}
                onNetworkUpdateCallback={this.onNetworkUpdateCallback}
                onAddressUpdateCallback={this.onAddressUpdateCallback} />
            </Row>
          </Container>
          <Container>
            <Outlet context={{
              state: this.state,
              setEmail: this.onEmailCallback,
              setDimension: this.onDimensionCallback,
              setUpload: this.onUploadCallback,
              setViews: this.onViewsCallback,
              setTxHash: this.onTxHashCallback,
              setExtension: this.onExtensionCallback,
              setErr: this.onErrorMsgCallback
            }} />

            <div className='learn-more'>
              <Trans i18nKey="learn_more">
                <a href="https://metaverseresidents.com/metaverse-advertising/#skip-to-faq" target={'_blank'} rel="noreferrer">learnmore</a>
              </Trans>
            </div>
          </Container>
        </div>
      </Suspense>);
  }
}

const TranslationApp = withTranslation()(App)
export default TranslationApp;
