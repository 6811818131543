import { IChainData } from "./types";
import supportedChains from "./chains"
import axios, { AxiosInstance } from 'axios'
import { IAssetData } from './types'

export function getChainData(chainId: number): IChainData {
    const chainData = supportedChains.filter(
        (chain: any) => chain.chain_id === chainId
    )[0];

    if (!chainData) {
        throw new Error("ChainId missing or not supported");
    }

    const API_KEY = "96a5af5cd5574f78a6e4c7915bd57baa";

    if (
        chainData.rpc_url.includes("infura.io") &&
        chainData.rpc_url.includes("%API_KEY%") &&
        API_KEY
    ) {
        const rpcUrl = chainData.rpc_url.replace("%API_KEY%", API_KEY);

        return {
            ...chainData,
            rpc_url: rpcUrl
        };
    }

    return chainData;
}



const api: AxiosInstance = axios.create({
    baseURL: 'https://ethereum-api.xyz',
    timeout: 30000, // 30 secs
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
})

export async function apiGetAccountAssets(
    address: string,
    chainId: number
): Promise<IAssetData[]> {
    const response = await api.get(
        `/account-assets?address=${address}&chainId=${chainId}`
    )
    const { result } = response.data
    return result
}


export const apiGetAccountNonce = async (
    address: string,
    chainId: number
): Promise<string> => {
    const response = await api.get(
        `/account-nonce?address=${address}&chainId=${chainId}`
    )
    const { result } = response.data
    return result
}
