// import '../App.css'
import React, { useState } from 'react';
import Nav from 'react-bootstrap/Nav';

import { Alert } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Range from 'react-bootstrap/FormRange';
import Row from 'react-bootstrap/Row';
import { Trans } from 'react-i18next';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { LargeScreenDimension, PaymentWallet, SmallScreenDimension } from '../App';
import kioskBigScreenImg from '../assets/kiosk-trans-greyscale-large-screen-highlight.png';
import kioskSmallScreenImg from '../assets/kiosk-trans-greyscale-small-screens-highlight.png';

const prices: Record<number, any> = {
    5000: { "512x340": { "USDC": 400 }, "512x512": { "USDC": 500 } },
    10000: { "512x340": { "USDC": 650 }, "512x512": { "USDC": 800 } },
    25000: { "512x340": { "USDC": 1400 }, "512x512": { "USDC": 1500 } },
    40000: { "512x340": { "USDC": 1800 }, "512x512": { "USDC": 2400 } },
    60000: { "512x340": { "USDC": 2500 }, "512x512": { "USDC": 3200 } },
}

const computeUSDC = (exchangeRate: number) => {
    Object.keys(prices).forEach(nViews => {
        let dims = prices[Number(nViews)];
        Object.keys(dims).forEach(dim => {
            let priceDict = prices[Number(nViews)][dim] as any;
            priceDict["ETH"] = (priceDict["USDC"] * exchangeRate);
            prices[Number(nViews)][dim] = priceDict;
        });
    });
}

const Purchase = () => {
    let ctx = useOutletContext<any>();
    const navigate = useNavigate();

    let state = ctx.state;
    computeUSDC(state.ethUsdcExchangeRate);

    const [currency, setCurrency] = useState<string>("ETH");
    const [numChanges, setNumChanges] = useState<number>(0);

    let email = state.emailAdddress;
    let dimension = state.dimension;
    let desiredViews = state.desiredViews;
    let runningTransaction = state.runningTransaction;
    let txHash = state.txHash;
    if (!dimension) {
        navigate("/select")
    }

    if (!email) {
        navigate("/contact")
    }

    if (!dimension) {
        dimension = LargeScreenDimension;
    }
    let priceList = Object.keys(prices).map(n => Number(n));
    let priceListIndex = priceList.findIndex(x => x === desiredViews);
    if (priceListIndex === -1) {
        priceListIndex = 0;
        desiredViews = priceList[priceListIndex];
    }

    const onPriceRange = async (e: React.FormEvent<HTMLInputElement>) => {
        let idx: number = +(e.target as HTMLInputElement).value;
        ctx.setViews(priceList[idx]);
        setNumChanges(numChanges + 1);
    }

    const onTransactionHash = async (transactionHash: string) => {
        ctx.setTxHash(transactionHash);

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        let desiredViews = state.desiredViews;
        if (state.desiredViews === 0) {
            desiredViews = priceList[0];
        }

        var raw = JSON.stringify({
            "txHash": transactionHash,
            "address": state.address,
            "fileName": state.objectId,
            "numViews": desiredViews,
            "dimension": state.dimension,
            "email": state.emailAdddress,
        });

        var requestOptions: RequestInit = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        // await fetch("http://localhost:5001/metabeacon/us-central1/savePurchase", requestOptions)
        await fetch("https://metabeacon.win/api/savePurchase", requestOptions)

        navigate("/success");
    }

    const getRawPrice = (): number => {
        let numViews = priceList[priceListIndex];
        return prices[numViews][dimension][currency];
    }

    const getPriceString = (): string => {
        let sum = getRawPrice();
        // let dc = sum * getDiscount();
        let dc = 0.0;
        let s = (sum - dc).toFixed(4).replace(/0+$/, '');
        if (s.endsWith(".")) {
            s = s.substring(0, s.length - 1)
        }
        return s;
    }

    const onBuy = async (e: React.FormEvent<HTMLButtonElement>) => {
        const gasPrice = await state.web3.eth.getGasPrice();
        const price = getPriceString();

        if (currency === "ETH") {
            const tx = {
                from: state.address,
                to: PaymentWallet,
                gasPrice,
                value: state.web3.utils.toWei(price, "ether"),
            };

            state.web3.eth.sendTransaction(tx)
                .on('transactionHash', onTransactionHash)
                .on('error', console.error);
        } else if (currency === "USDC") {
            let minABI = [
                {
                    "constant": false,
                    "inputs": [
                        {
                            "name": "_to",
                            "type": "address"
                        },
                        {
                            "name": "_value",
                            "type": "uint256"
                        }
                    ],
                    "name": "transfer",
                    "outputs": [
                        {
                            "name": "success",
                            "type": "bool"
                        }
                    ],
                    "payable": false,
                    "stateMutability": "nonpayable",
                    "type": "function"
                }
            ];
            let contractAddress = "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48";
            let contract = await new state.web3.eth.Contract(minABI, contractAddress);
            // picoether matches the decimals of USDC
            contract.methods.transfer(PaymentWallet, state.web3.utils.toWei(price, "picoether")).send({
                from: state.address,
                gasPrice: gasPrice,
                // this limit has been found by searching several transactions executing the transfer ABI
                gas: 100000,
            }).on('transactionHash', onTransactionHash)
                .on('error', console.error);
        }
    }

    return (
        <Container className='p-5 mb-4 rounded-3'>
            <h1 className="header-purchase">
                <Trans i18nKey="page_6_top_header" />
            </h1>
            <h3 className="header-purchase">
                <Trans i18nKey="page_6_top_message">
                    <a href="https://nftplazas.com/advertise-in-the-metaverse" target={'_blank'} rel="noreferrer">learnmore</a>
                </Trans>
            </h3>
            <p><Trans i18nKey="page_6_gas_message" /></p>
            {numChanges > 1.5 * priceList.length &&
                <Alert variant="light">
                    Can't find the right package? <a href="https://nftplazas.com/contact/" target={'_blank'} rel="noreferrer">Contact Us</a>
                </Alert>
            }

            <br /><br />
            <Row>
                <Col className='upload-img-purchase-col'>
                    {dimension === LargeScreenDimension &&
                        <img src={kioskBigScreenImg} className="upload-img-purchase" alt="img"></img>
                    }
                    {dimension === SmallScreenDimension &&
                        <img src={kioskSmallScreenImg} className="upload-img-purchase" alt="img"></img>
                    }
                </Col>
                <Col>
                    <Nav fill variant="pills" defaultActiveKey={currency}
                        onSelect={(selectedKey) => setCurrency(selectedKey ?? "ETH")}>
                        <Nav.Item>
                            <Nav.Link eventKey="ETH">ETH</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="USDC">USDC</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <br />
                    <p>Get <b>{desiredViews}</b> Views for only <b>{getPriceString()}</b> {currency}</p>
                    <Range max={priceList.length - 1} step="1" value={priceListIndex} onInput={onPriceRange} />
                    <Button onClick={onBuy} disabled={runningTransaction} className="sc_button">
                        {runningTransaction &&
                            <div>
                                <div className="spinner-border" role="status" />
                                <p>Waiting for transaction hash... {txHash}</p>
                            </div>
                        }
                        {!runningTransaction && <div>Buy</div>}
                    </Button>
                </Col>
            </Row>
        </Container>
    );
}

export default Purchase;

