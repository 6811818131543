// import '../App.css'
import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useOutletContext } from "react-router-dom";

import dclBookNew from '../assets/dcl-book-new.png';
import cvBookNew from '../assets/cv-book-new.png';
import ssBookNew from '../assets/ss-book-new.png';
import sandboxBookNew from '../assets/sandbox-book-new.png';

import dclConnectedImg from '../assets/decentraland-main-button.png';
import cvConnectedImg from '../assets/kiosk-trans-new-booking-system-cv.png';
import sandboxConnectedImg from '../assets/kiosk-trans-new-booking-system-sandb.png';
import ssConnectedImg from '../assets/kiosk-trans-new-booking-system-ss.png';

// landing page
const LandingPage = (props: any) => {
    const ctx = useOutletContext<any>().state;
    const navigate = useNavigate();
    const connected = ctx.connected;
    // 1 signals ethereum mainnet, 5 is goerli
    const network = ctx.network;
    const correctNetwork = network === 1;
    return (
        <Container>
            {!connected &&
                <Row>
                    <Container className="p-5 mb-4 rounded-3">
                        <h1 className="header">
                            <Trans i18nKey="page_1_top_header" />
                        </h1>

                        <h3 className="header">
                            <Trans i18nKey="page_1_top_message">
                                <a href="https://nftplazas.com/advertise-in-the-metaverse" target={'_blank'} rel="noreferrer">learnmore</a>
                            </Trans>
                        </h3>

                        <Row className='logo-row'>
                            <Col className='col-3'>
                                <img className='mv-logo img-responsive' src={dclBookNew} alt="logo" />
                            </Col>

                            <Col className='col-3'>
                                <img className='mv-logo img-responsive' src={cvBookNew} alt="logo" />
                            </Col>

                            <Col className='col-3'>
                                <img className='mv-logo img-responsive' src={ssBookNew} alt="logo" />
                            </Col>

                            <Col className='col-3'>
                                <img className='mv-logo img-responsive' src={sandboxBookNew} alt="logo" />
                            </Col>
                        </Row>
                    </Container>
                </Row>
            }
            {connected &&
                <Row>
                    <Container className="p-5 mb-4 rounded-3">
                        <h1 className="header">
                            <Trans i18nKey="page_2_top_header" />
                        </h1>

                        {!correctNetwork &&
                            <h2 style={{ color: "lightsalmon" }}><Trans i18nKey="page_2_wrong_network" /></h2>
                        }

                        {correctNetwork &&
                            <Container>
                                <h3 className="header">
                                    <Trans i18nKey="page_2_top_message">
                                        <a href="https://nftplazas.com/advertise-in-the-metaverse" target={'_blank'} rel="noreferrer">learnmore</a>
                                    </Trans>
                                </h3>
                                <Row className='logo-row'>
                                    <Col className='col-3'>
                                        <Button
                                            type="submit"
                                            className="decentraland-select-btn"
                                            onClick={() => navigate("/contact")}>
                                            <img className='mv-logo' src={dclConnectedImg} alt="logo" />
                                        </Button>
                                    </Col>

                                    <Col className='col-3'>
                                        <a href='https://nftplazas.com/promotion/' target={'_blank'} rel="noreferrer">
                                            <img className='mv-logo' src={cvConnectedImg} alt="logo" />
                                        </a>
                                    </Col>

                                    <Col className='col-3'>
                                        <a href='https://nftplazas.com/promotion/' target={'_blank'} rel="noreferrer">
                                            <img className='mv-logo' src={ssConnectedImg} alt="logo" />
                                        </a>
                                    </Col>

                                    <Col className='col-3'>
                                        <img className='mv-logo' src={sandboxConnectedImg} alt="logo" />
                                    </Col>
                                </Row>
                            </Container>
                        }
                    </Container>
                </Row>
            }
        </Container>
    );
}

export default LandingPage;

