import mixpanel from 'mixpanel-browser';
import { render } from "react-dom";
import { hotjar } from "react-hotjar";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from './App';
import Contact from "./component/contact";
import LandingPage from "./component/landing";
import Purchase from "./component/purchase";
import Extend from "./component/extend";
import SizeSelect from "./component/size_select";
import Success from "./component/success";
import Upload from "./component/upload";
import './i18n';
import './index.css';
import UploadResize from './component/upload_resize';

hotjar.initialize(2976642, 6);
mixpanel.init('f26f47967ec82bad7470d04024404b99', { debug: true });


render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} >
        <Route path="" element={<LandingPage />} />
        <Route path="contact" element={<Contact />} />
        <Route path="select" element={<SizeSelect />} />
        <Route path="upload" element={<Upload />} />
        <Route path="uploadng" element={<UploadResize />} />
        <Route path="purchase" element={<Purchase />} />
        <Route path="success" element={<Success />} />
        <Route path="extend" element={<Extend />} />
      </Route>
    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
);
