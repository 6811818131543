import WalletConnectProvider from '@walletconnect/web3-provider';
import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import WalletLink from 'walletlink';
import Web3 from "web3";
import Web3Modal, { IProviderOptions } from "web3modal";
import { getChainData } from '../blockchain/api';
import { IWalletHeaderState } from '../blockchain/types';


const INITIAL_STATE: IWalletHeaderState = {
  connected: false,
  address: '',
  fetching: false,
  web3: null,
  provider: null,
  chainId: 1,
  networkId: 1,
  assets: [],
  showModal: false,
  pendingRequest: false,
  result: null
};

function initWeb3(provider: any) {
  const web3: any = new Web3(provider);

  web3.eth.extend({
    methods: [
      {
        name: "chainId",
        call: "eth_chainId",
        outputFormatter: web3.utils.hexToNumber
      }
    ]
  });

  return web3;
}

class WalletConnect extends React.Component<any, any> {
  // @ts-ignore
  public web3Modal: Web3Modal;
  public walletState: IWalletHeaderState;

  constructor(props: any) {
    super(props);
    this.walletState = {
      ...INITIAL_STATE
    };

    this.handleNewConnection = this.handleNewConnection.bind(this);
    this.handleNewDisconnection = this.handleNewDisconnection.bind(this);
    this.handleNewChain = this.handleNewChain.bind(this);
    this.handleNewAddress = this.handleNewAddress.bind(this);

    this.web3Modal = new Web3Modal({
      network: getChainData(this.walletState.chainId).network,
      cacheProvider: true,
      providerOptions: this.getProviderOptions()
    });
  }

  public async handleNewConnection(address: string, network: number, web3: any) {
    await this.props.onAddressCallback(address, network, web3);
  }

  public async handleNewChain(network: number) {
    await this.props.onNetworkUpdateCallback(network);
  }

  public async handleNewAddress(address: string) {
    await this.props.onAddressUpdateCallback(address);
  }

  public async handleNewDisconnection(address: string) {
    await this.props.onDisconnectCallback(address);
  }

  public getProviderOptions = () => {
    const infuraId = "de20d7f8dc724b528932f112a08b16d2";
    const providerOptions: IProviderOptions = {
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          infuraId: infuraId
        }
      },
      walletlink: {
        package: WalletLink,
        options: {
          appName: "MetaBeacon",
          infuraId: infuraId,
          rpc: ""
        }
      }
    };

    return providerOptions;
  };

  public componentDidMount() {
    if (this.web3Modal.cachedProvider) {
      this.onConnectWallet();
    }
  }

  public toggleModal = () =>
    this.setState({ showModal: !this.state.showModal });

  public onConnectWallet = async () => {
    const provider = await this.web3Modal.connect();
    await this.subscribeProvider(provider);
    await provider.enable();
    const web3: any = initWeb3(provider);
    const accounts = await web3.eth.getAccounts();
    const address = accounts[0];
    const networkId = await web3.eth.net.getId();
    const chainId = await web3.eth.chainId();
    await this.setState({
      web3,
      provider,
      connected: true,
      address,
      chainId,
      networkId
    });
    await this.handleNewConnection(address, chainId, web3);
  };

  public resetApp = async () => {
    const { web3 } = this.walletState;
    const address = this.walletState.address;
    if (web3 && web3.currentProvider && web3.currentProvider.close) {
      await web3.currentProvider.close();
    }
    await this.web3Modal.clearCachedProvider();
    this.setState({ ...INITIAL_STATE });
    await this.handleNewDisconnection(address);
  };

  public subscribeProvider = async (provider: any) => {
    if (!provider.on) {
      return;
    }
    provider.on("disconnect", async (error: { code: number; message: string }) => {
      console.log(error);
      await this.resetApp();
    });
    provider.on("accountsChanged", async (accounts: string[]) => {
      if (accounts.length === 0) {
        await this.resetApp();
      } else {
        await this.setState({ address: accounts[0] });
        await this.handleNewAddress(accounts[0]);
      }
    });
    provider.on("chainChanged", async (chainId: string) => {
      // why does one make this a hex string?!
      let cx = parseInt(chainId);
      await this.setState({ chainId: cx });
      await this.handleNewChain(cx);
    });
  };

  public render = () => {
    const connected = this.props.connected;
    const address = this.props.address;
    return (
      <Col className='connect-wallet'>
        {!connected &&
          <Button onClick={this.onConnectWallet} className="sc_button">Connect Wallet</Button>
        }
        {connected &&
          <div className='address'>
            <p id="publicKey">{address}</p>
          </div>
        }
      </Col>
    );
  }
}

export default WalletConnect;

