// import '../App.css'
import mixpanel from 'mixpanel-browser';
import React from 'react';
import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import { hotjar } from 'react-hotjar';
import { Trans } from 'react-i18next';
import { useNavigate, useOutletContext } from 'react-router-dom';

// page to get email and accept T&Cs
const Contact = () => {
    const navigate = useNavigate();
    let ctx = useOutletContext<any>();

    const handleEmailChange = async (event: any) => {
        ctx.setEmail(event.target.value);
    }

    const handleEmailSubmit = async (event: any) => {
        event.preventDefault();

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
            return
        }

        hotjar.identify(ctx.state.emailAdddress, { email: ctx.state.emailAdddress, wallet: ctx.state.address });
        mixpanel.track('Contact', {
            'email': ctx.state.emailAdddress,
            'wallet': ctx.state.address,
        });

        navigate("/select")
    }

    return (
        <Container className='p-5 mb-4 rounded-3 contact-container'>
            <h1 className="header">
                <Trans i18nKey="page_3_top_header" />
            </h1>
            <Form className='tnc-form container justify-content-center align-items-center' onSubmit={handleEmailSubmit}>
                <Form.Group className="mb-3">
                    <Form.Label>
                        <Trans i18nKey="page_3_top_message" />
                    </Form.Label>
                    <Form.Control required type="email" placeholder="name@example.com"
                        tabIndex={1}
                        value={ctx.state.emailAdddress}
                        onChange={handleEmailChange} />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Check type='checkbox'>
                        <Form.Check.Input type='checkbox' required tabIndex={2} />
                        <Form.Check.Label tabIndex={-1}>
                            <Trans i18nKey="page_3_tnc">
                                <a href="https://nftplazas.com/metaverse-advertising/booking-system-terms-conditions/" target={'_blank'} rel="noreferrer">Terms and Conditions</a>
                            </Trans>
                        </Form.Check.Label>
                    </Form.Check>
                </Form.Group>
                <Button type="submit" className="sc_button" tabIndex={3}>Continue</Button>
            </Form>
        </Container >
    );
}

export default Contact;

