// import '../App.css'
import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Trans } from 'react-i18next';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { LargeScreenDimension, SmallScreenDimension } from '../App';
import kioskBigScreenImg from '../assets/kiosk-trans-greyscale-large-screen-highlight.png';
import kioskSmallScreenImg from '../assets/kiosk-trans-greyscale-small-screens-highlight.png';


const SizeSelect = () => {
  const ctx = useOutletContext<any>();
  const navigate = useNavigate();
  let state = ctx.state;
  let email = state.emailAdddress;

  if (!email) {
    navigate("/contact")
  }

  const handleSubmit = (dimension: string) => {
    ctx.setDimension(dimension);
    navigate("/uploadng");
  }

  return (
    <Container className='p-5 mb-4 rounded-3'>
      <h1 className="header">
        <Trans i18nKey="page_4_top_header" />
      </h1>
      <Row>
        <Col  className='select-col-large'>
          <Button
            type="submit"
            className="decentraland-select-btn"
            onClick={() => handleSubmit(LargeScreenDimension)}>
            <p>
              <Trans i18nKey="page_4_large_screen_price_hint" />
            </p>
            <img className='select-img' src={kioskBigScreenImg} alt="img"></img>
            <h2>
              <Trans i18nKey="page_4_large_screen" />
            </h2>
            <p>
              <Trans i18nKey="page_4_large_screen_description" />
            </p>
          </Button>
        </Col>
        <Col className='select-col-small'>
          <Button
            type="submit"
            className="decentraland-select-btn"
            onClick={() => handleSubmit(SmallScreenDimension)}>
            <p>
              <Trans i18nKey="page_4_small_screen_price_hint" />
            </p>
            <img className='select-img' src={kioskSmallScreenImg} alt="img"></img>
            <h2>
              <Trans i18nKey="page_4_small_screen" />
            </h2>
            <p>
              <Trans i18nKey="page_4_small_screen_description" />
            </p>
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default SizeSelect;

