import React from 'react';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';

import { Trans } from 'react-i18next';
import { useNavigate, useOutletContext } from 'react-router-dom';

const Success = () => {
    const ctx = useOutletContext<any>();
    const navigate = useNavigate();

    let state = ctx.state;
    let dimension = state.dimension;

    // in case somebody ended up directly on /success without any state
    if (!dimension) {
        navigate("/")
    }

    return (
        <Container className='p-5 mb-4 rounded-3'>
            <h1 className="header">
                <Trans i18nKey="page_7_top_header" />
            </h1>
            <div className="muted">
                <Trans i18nKey="page_7_top_message" />
            </div>
            <br />
            <div className="muted">
                <Trans i18nKey="page_7_bottom_message" />
            </div>
            <br />
            <div>
                <div>Helpful Links:</div>
                <ul className='helpful-links'>
                    <li>
                        <a href="https://metaverseresidents.com/metaverse-advertising/#skip-to-faq" target={'_blank'} rel="noreferrer">FAQ Section</a>
                    </li>
                    <li>
                        <a href="https://nftplazas.com/metaverse-advertising/map-locations/" target={'_blank'} rel="noreferrer">Our Locations</a>
                    </li>
                    <li>
                        <a href="https://nftplazas.com/contact/" target={'_blank'} rel="noreferrer">Contact Us</a>
                    </li>
                </ul>
                <div>
                    <Button
                        type="submit"
                        className="sc_button"
                        onClick={() => navigate("/select")}>
                        Book Another Campaign!
                    </Button>
                </div>
            </div>
            <br />
            <div className="muted">
                <Trans i18nKey="page_7_footer_message_top" />
            </div>
            <aside id="widget_contacts_1852632754_widget" className="widget widget_contacts">
                <div className="contacts_wrap">
                    <div className="contacts_info"><span className="contacts_email"><a href="mailto:contact@nftplazas.com">contact@nftplazas.com</a></span></div>
                    <div className="contacts_socials socials_wrap">
                        <a target="_blank" href="https://www.facebook.com/nftplazas" rel="noreferrer" className="social_item social_item_style_icons social_item_type_icons">
                            <span className="social_icon social_icon_facebook">
                                <span className="icon-facebook"></span>
                            </span>
                        </a>
                        <a target="_blank" href="https://twitter.com/NFTPlazas" rel="noreferrer" className="social_item social_item_style_icons social_item_type_icons">
                            <span className="social_icon social_icon_twitter">
                                <span className="icon-twitter"></span>
                            </span>
                        </a>
                        <a target="_blank" href="https://www.linkedin.com/company/77104277" rel="noreferrer" className="social_item social_item_style_icons social_item_type_icons">
                            <span className="social_icon social_icon_linkedin">
                                <span className="icon-linkedin"></span>
                            </span>
                        </a>
                        <a target="_blank" href="https://www.instagram.com/nftplazas/" rel="noreferrer" className="social_item social_item_style_icons social_item_type_icons">
                            <span className="social_icon social_icon_instagramm">
                                <span className="icon-instagramm"></span>
                            </span>
                        </a>
                        <a target="_blank" href="https://www.youtube.com/channel/UCYKElPbw0D1a6GE1McBH2iQ" rel="noreferrer" className="social_item social_item_style_icons social_item_type_icons">
                            <span className="social_icon social_icon_youtube">
                                <span className="icon-youtube"></span>
                            </span>
                        </a>
                        <a target="_blank" href="https://mailchi.mp/ecadf05eb1bf/1" rel="noreferrer" className="social_item social_item_style_icons social_item_type_icons">
                            <span className="social_icon social_icon_mail">
                                <span className="icon-mail"></span>
                            </span>
                        </a>
                    </div>
                </div>
            </aside>
            <br />
            <div className="muted">
                <Trans i18nKey="page_7_footer_message_bottom" />
            </div>
        </Container >
    );
}

export default Success;

