import React, { useEffect } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';

import { Trans } from 'react-i18next';
import { useNavigate, useOutletContext, useLocation } from 'react-router-dom';

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Extend = () => {
    const ctx = useOutletContext<any>();
    const navigate = useNavigate();
    const query = useQuery();

    let state = ctx.state;
    let connected = state.connected;
    // 1 signals ethereum mainnet, 3 is ropsten
    let network = state.network;
    let correctNetwork = network === 1;

    let txHash = query.get("id")
    if (!txHash) {
        navigate("/")
    }

    let loading = connected && state.dimension === "";
    let err = state.errorMsg !== "";


    useEffect(() => {
        fetch(`https://metabeacon.win/api/extend?tx=${txHash}`)
            .then(response => response.json())
            .then(data => {
                if (data.result === "success") {
                    ctx.setExtension(data.purchase, data.file, data.contentType);
                }
            }).catch(e => {
                console.log(e);
                ctx.setErr("Failed to retrieve the campaign, please try again later");
            });
        // eslint-disable-next-line
    }, []);


    return (
        <Container className='mb-4 rounded-3'>
            <h2>
                <Trans i18nKey="page_8_top_message" />
            </h2>

            <br />


            {!err &&
                <Container>
                    {!connected &&
                        <h3 className="header">
                            <Trans i18nKey="page_1_top_message">
                                <a href="https://nftplazas.com/advertise-in-the-metaverse" target={'_blank'} rel="noreferrer">learnmore</a>
                            </Trans>
                        </h3>
                    }

                    {connected &&
                        <Container>
                            {!correctNetwork &&
                                <h2 style={{ color: "lightsalmon" }}><Trans i18nKey="page_2_wrong_network" /></h2>
                            }

                            {correctNetwork &&
                                <Container>
                                    {loading &&
                                        <Container>
                                            <p>Loading your campaign....</p>
                                            <Spinner animation="border" role="status" />
                                        </Container>
                                    }

                                    {!loading &&
                                        <Container>
                                            <p>
                                                <Trans i18nKey="page_9_welcome_message"
                                                    values={{ email: state.emailAdddress }}
                                                    components={{ b: <b /> }}
                                                />

                                            </p>
                                            <div>
                                                <Button
                                                    type="submit"
                                                    className="sc_button"
                                                    onClick={() => navigate("/purchase")}>
                                                    Book Again
                                                </Button>
                                            </div>
                                            <br />
                                            <img src={`data:${state.contentTypeImg};base64,${state.base64Img}`} className="extend-img" alt="campaign" />
                                        </Container>
                                    }
                                </Container>
                            }

                        </Container>
                    }
                </Container>
            }

            {err &&
                <Container>
                    <p>{state.errorMsg}</p>
                </Container>
            }

        </Container >
    );
}

export default Extend;

