// import '../App.css'
import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Dropzone, { IDropzoneProps, IFileWithMeta } from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import { Trans } from 'react-i18next';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { LargeScreenDimension, SmallScreenDimension } from '../App';
import kioskBigScreenImg from '../assets/kiosk-trans-greyscale-large-screen-highlight.png';
import kioskSmallScreenImg from '../assets/kiosk-trans-greyscale-small-screens-highlight.png';


const Upload = () => {
  let ctx = useOutletContext<any>();
  console.log(ctx);
  const navigate = useNavigate();
  let dimension = ctx.state.dimension;
  let dx = dimension.split("x");
  let desiredWidth = parseInt(dx[0])
  let desiredHeight = parseInt(dx[1])

  const validateFileDimensions: any = (file: IFileWithMeta) => {
    let height = file.meta.height;
    let width = file.meta.width;

    if (width === desiredWidth && height === desiredHeight) {
      return false
    }

    return `Incompatible dimensions found ${width}x${height}, expected ${dimension}`;
  }

  const getUploadParams: IDropzoneProps['getUploadParams'] = async (f: IFileWithMeta) => {
    let response = await fetch('https://metabeacon.win/api/signurl');
    const { signedUrl, objectUrl } = await response.json();
    // sneak the object id into the request for the next button
    (f.meta as any).objectUrl = objectUrl;
    return {
      method: 'put',
      body: f.file,
      meta: f.meta,
      url: signedUrl,
    }
  }

  const handleFileUploadSubmit: IDropzoneProps['onSubmit'] = async (files, allFiles) => {
    const f = files[0];
    const objectUrl = (f.meta as any).objectUrl;
    // const dimension = `${f.meta.width}x${f.meta.height}`
    await ctx.setUpload(objectUrl)
    allFiles.forEach(f => f.remove())
    navigate("/purchase");
  }

  return (
    <Container className='p-5 mb-4 rounded-3'>
      <h1 className="header">
        <Trans i18nKey="page_5_top_header" />
      </h1>
      <Row>
        <Col>
          <p>
            <Trans i18nKey="page_5_upload_message_top" />
          </p>
          {dimension === LargeScreenDimension &&
            <img src={kioskBigScreenImg} className="upload-img" alt="img"></img>
          }
          {dimension === SmallScreenDimension &&
            <img src={kioskSmallScreenImg} className="upload-img" alt="img"></img>
          }
          <p className='muted-text'>
            <Trans i18nKey="page_5_upload_message_bottom" />
          </p>
          <ul className='muted-text'>
            <li>Image must be of size {dimension} in png/jpg format</li>
            <li>Maximum file size of 1.5 megabytes, smaller files load faster</li>
          </ul>
        </Col>
        <Col>
          <Dropzone
            getUploadParams={getUploadParams}
            onSubmit={handleFileUploadSubmit}
            validate={validateFileDimensions}
            submitButtonContent="Continue"
            accept="image/jpeg,image/png"
            inputContent={(files, extra) => (extra.reject ? 'JPG and PNG image files only' : 'CLICK HERE to upload your billboard image')}
            maxFiles={1}
            multiple={false}
            canCancel={false}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default Upload;

